.container {
	padding: 0 0 110px 0;
}
.comic {
	padding: 5px;
	margin: 0 auto;
	padding: 120px 0 0 0;
	width: 500px;
	max-width: 100%;
	text-align: left;
}

.comic img {
	max-width: 100%;
	height: auto;
}

.comic h2 {
	line-height: 50%;
}
