.logo {
	text-align: center;
	font-family: "Special Elite", monospace;
	font-size: 3.5em;
	padding: 20px;
}

.logo img {
	width: 60px;
	height: 60px;
}

.logo a, .logo Link {
	color: black;
	text-decoration: none;
}

@media only screen and (max-width: 480px) {
	.logo {

		font-size: 1.8em;
	}
	.logo img {
		width: 45px;
		height: 45px;
	}
}

