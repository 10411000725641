.search {
	position: fixed;
	background-color: #251f50;
	height: 100%;
	width: 60%;
	max-width: 300px;
	right: 0;
	top: 100px;
	overflow: scroll;
	text-align: left;
	font-family: "Special Elite", monospace;
	font-size: 1.5em;
	padding: 0 10px 100px 10px;
	z-index: 16;
}

.search ul {
	background-color: #ffd91d;
	padding: 0;
}

.search li {
	display: list-item;
	list-style-position: inside;
	border-bottom: 3px solid #4e4e5e;
	padding: 10px 0;
	margin: 0;
}
