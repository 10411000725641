@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App_App__16ZpL {
	text-align: center;
}
.App_App__16ZpL a, .App_App__16ZpL Button {
	color: #696980;
}
.App_App__16ZpL a:hover, .App_App__16ZpL Button:hover {
	color: #4e4e5e;
}

.App_App__16ZpL h2 {
	font-family: "Special Elite", monospace;
	font-size: 2em;
}

.App_App__16ZpL h3 {
	font-family: "Special Elite", monospace;
	font-size: 1.7em;
}
@media only screen and (min-width: 701px) {
	.App_desktopSidebarWrapper__3A02O {
		margin: 0 0 0 300px;
	}
}

.Nav_nav__2l-YZ ul {
  list-style-type: none;
  padding-left: 0;
}

.Nav_nav__2l-YZ li {
  display: inline-block;
  padding: 3px;
}

.MobileHeader_mobileHeader__2pzA5 {
  background: #ffd91d;
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 20;
}

.Logo_logo__E2G7i {
	text-align: center;
	font-family: "Special Elite", monospace;
	font-size: 3.5em;
	padding: 20px;
}

.Logo_logo__E2G7i img {
	width: 60px;
	height: 60px;
}

.Logo_logo__E2G7i a, .Logo_logo__E2G7i Link {
	color: black;
	text-decoration: none;
}

@media only screen and (max-width: 480px) {
	.Logo_logo__E2G7i {

		font-size: 1.8em;
	}
	.Logo_logo__E2G7i img {
		width: 45px;
		height: 45px;
	}
}


.MobileNav_backdrop__2l9Fy {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
	background: rgba(0, 0, 0, 0.75);
  }
  
.MobileNav_mobileNav__33YdN {
	background: #ffd91d;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 65px;
	z-index: 20;
	font-family: "Special Elite", monospace;
	color: #696980;
}

.MobileNav_mobileNav__33YdN img {
	width: 35px;
	height: 35px;
}

.Search_search__1lRqO {
	position: fixed;
	background-color: #251f50;
	height: 100%;
	width: 60%;
	max-width: 300px;
	right: 0;
	top: 100px;
	overflow: scroll;
	text-align: left;
	font-family: "Special Elite", monospace;
	font-size: 1.5em;
	padding: 0 10px 100px 10px;
	z-index: 16;
}

.Search_search__1lRqO ul {
	background-color: #ffd91d;
	padding: 0;
}

.Search_search__1lRqO li {
	display: list-item;
	list-style-position: inside;
	border-bottom: 3px solid #4e4e5e;
	padding: 10px 0;
	margin: 0;
}

.Sidebar_sidebar__E9UUQ {
	position: fixed;
	background-color: #251f50;
	height: 100%;
	width: 60%;
	max-width: 300px;
	left: 0;
	top: 100px;
	overflow: scroll;
	text-align: left;
	font-family: "Special Elite", monospace;
	font-size: 1.5em;
	line-height: 120%;
	padding: 0 0 100px 0;
	margin: 0 0 50px 0;
	z-index: 14;
}

.Sidebar_sidebar__E9UUQ ul {
	background-color: #251f50;
	padding: 0;
}

.Sidebar_sidebar__E9UUQ ol {
	background-color: #ffd91d;
	padding-left: 0;
}

.Sidebar_sidebar__E9UUQ li {
	display: list-item;
	list-style-position: inside;
	border-bottom: 3px solid #4e4e5e;
	padding: 10px 0;
	margin: 0;
}

.Sidebar_spacer__1QMag {
	position: relative;
	float: left;
	height: 100vh;
	width: 300px;
}

@media only screen and (max-width: 480px) {
	.Sidebar_sidebar__E9UUQ {
		top: 70px;
	}
}

.DesktopNav_backdrop__29_RZ {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
	background: rgba(0, 0, 0, 0.75);
  }


.DesktopNav_desktopNav__d-39F {
	background: #ffd91d;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100px;
	z-index: 20;
}

.DesktopNav_menu__33pzP {
	position: absolute;
	left: 0;
	top: 0;
	padding: 10px;
}

.DesktopNav_search__193vF {
	position: absolute;
	right: 0;
	top: 0;
	padding: 10px;
}

.DesktopNav_desktopNav__d-39F Button {
	color: #696980;
	font-family: "Special Elite", monospace;
}

.Comics_container__2SQgk {
	padding: 0 0 110px 0;
}
.Comics_comic__2oyGi {
	padding: 5px;
	margin: 0 auto;
	padding: 120px 0 0 0;
	width: 500px;
	max-width: 100%;
	text-align: left;
}

.Comics_comic__2oyGi img {
	max-width: 100%;
	height: auto;
}

.Comics_comic__2oyGi h2 {
	line-height: 50%;
}

.Comic_container__3Xrlc {
	padding: 0 0 110px 0;
}
.Comic_comic__2jTxw {
	padding: 5px;
	margin: 0 auto;
	padding: 120px 0 0 0;
	width: 500px;
	max-width: 100%;
	text-align: left;
}

.Comic_comic__2jTxw img {
	max-width: 100%;
	height: auto;
}

.Comic_comic__2jTxw h2 {
	line-height: 50%;
}

.Comic_copyright__1604Z {
	margin-top: -30px;
	text-align: left;
}

.Share_share__2cJx_ {
    text-align: right;
}

.Share_share__2cJx_ Toast {
    text-align: right;
}
.Transcript_transcript__3QGGe {
    white-space: pre-line;
    vertical-align: bottom;
    /* https://stackoverflow.com/questions/45856051/react-i18n-break-lines-in-json-string */
}
/* .transcript p {
    padding: 20px;
} */
.About_about__eBiEh {
	padding: 5px;
	margin: 100px auto 100px auto;
	width: 700px;
	max-width: 95%;
	text-align: left;
}
.About_about__eBiEh img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
}
.About_aboutBio__1cOAQ {
	padding: 10px 0 0 10px;
}
.About_aboutBio__1cOAQ img {
	float: left;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	padding: 5px;
}
.About_aboutBio__1cOAQ::after {
	content: "";
	clear: both;
	display: table;
  }
.Contact_contact__3wkKc {
	padding: 5px;
	margin: 100px auto 0 auto;
	max-width: 100%;
	text-align: left;
}
