.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
	background: rgba(0, 0, 0, 0.75);
  }


.desktopNav {
	background: #ffd91d;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100px;
	z-index: 20;
}

.menu {
	position: absolute;
	left: 0;
	top: 0;
	padding: 10px;
}

.search {
	position: absolute;
	right: 0;
	top: 0;
	padding: 10px;
}

.desktopNav Button {
	color: #696980;
	font-family: "Special Elite", monospace;
}
