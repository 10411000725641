.App {
	text-align: center;
}
.App a, .App Button {
	color: #696980;
}
.App a:hover, .App Button:hover {
	color: #4e4e5e;
}

.App h2 {
	font-family: "Special Elite", monospace;
	font-size: 2em;
}

.App h3 {
	font-family: "Special Elite", monospace;
	font-size: 1.7em;
}
@media only screen and (min-width: 701px) {
	.desktopSidebarWrapper {
		margin: 0 0 0 300px;
	}
}
