.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
	background: rgba(0, 0, 0, 0.75);
  }
  
.mobileNav {
	background: #ffd91d;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 65px;
	z-index: 20;
	font-family: "Special Elite", monospace;
	color: #696980;
}

.mobileNav img {
	width: 35px;
	height: 35px;
}
