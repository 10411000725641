.sidebar {
	position: fixed;
	background-color: #251f50;
	height: 100%;
	width: 60%;
	max-width: 300px;
	left: 0;
	top: 100px;
	overflow: scroll;
	text-align: left;
	font-family: "Special Elite", monospace;
	font-size: 1.5em;
	line-height: 120%;
	padding: 0 0 100px 0;
	margin: 0 0 50px 0;
	z-index: 14;
}

.sidebar ul {
	background-color: #251f50;
	padding: 0;
}

.sidebar ol {
	background-color: #ffd91d;
	padding-left: 0;
}

.sidebar li {
	display: list-item;
	list-style-position: inside;
	border-bottom: 3px solid #4e4e5e;
	padding: 10px 0;
	margin: 0;
}

.spacer {
	position: relative;
	float: left;
	height: 100vh;
	width: 300px;
}

@media only screen and (max-width: 480px) {
	.sidebar {
		top: 70px;
	}
}
