.about {
	padding: 5px;
	margin: 100px auto 100px auto;
	width: 700px;
	max-width: 95%;
	text-align: left;
}
.about img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
}
.aboutBio {
	padding: 10px 0 0 10px;
}
.aboutBio img {
	float: left;
	width: 150px;
	height: 150px;
	border-radius: 50%;
	padding: 5px;
}
.aboutBio::after {
	content: "";
	clear: both;
	display: table;
  }